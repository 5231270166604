// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
var SmoothScroll = require("smooth-scroll")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import('./stylesheets/application.scss');
import "@fortawesome/fontawesome-free/js/all";
import "video.js/dist/video.js";

document.addEventListener("turbolinks:load", () => {
	$('[data-toggle="tooltip"]').tooltip();
})

$(document).ready(function() {
	var scroll = new SmoothScroll('a.smooth-scroll[href^="#"]');
	$('.variant-selector select').change()
});

$(document).on('click','#product_content:not(.open)',function(){
	$('#product_content').toggleClass('open')
	return false;
})

$(document).on('click','.media-gallery-image',function(){
	var $this = $(this)
	var $mediaGallery = $this.parent('.media-gallery')
	var $target = $($mediaGallery.data('target'))
	$target.attr('src',$this.data('src'))
	$('.media-gallery-image',$mediaGallery).removeClass('active')
	$this.addClass('active')
	return false;
})

$(document).on('custom.selected','.variant-buy-block',function(){
	var $this = $(this)
	var $parent = $this.parent('.variant-buy-blocks')
	$('.variant-buy-block',$parent).removeClass('active')
	$this.addClass('active')
	return false;
})

$(document).on('change','.variant-selector select',function(){
	var select_this = this
	var $this = $(select_this)
	var $variant_selector = $this.parents('.variant-selector')
	var $variant_selected = $variant_selector.find('.variant-selector-selected')
	var $parent = $this.parents('.variant-selectors')
	var $variant_selects = $parent.find('.variant-selector select')
	var selected_option = $this.find(":selected").text()
	var variant_vals = $variant_selects.map(function() { return $(this).find(":selected").text() }).get();
	var variant_image_selector = ".variant--"+variant_vals.join('--').toLowerCase().replace(/\s+/g,'-');
	$(variant_image_selector).click()
	$(variant_image_selector).trigger('custom.selected')

	if( $('.variant-buy-block'+variant_image_selector).length == 0 ) {
		var height = $('.variant-buy-block.active').height()
		$('.variant-buy-block.out-of-stock').trigger('custom.selected')
		$('.variant-buy-block.out-of-stock .product-page-price').html('Out of Stock')
	}

	// Check to see which options need to be disabled.
	$parent.find('.variant-selector select').each(function() {
		var select_a = this
		$(select_a).find('option').each(function() {
			var option_a = this
			var option_a_val = $(option_a).attr('value')
			$parent.find('.variant-selector select').each(function() {
				var select_b = this
				if ( select_b != select_a ) {
					$(select_b).find('option').each(function() {
						var option_b = this
						var option_b_val = $(option_b).attr('value')
						

						var selector = ".variant-buy-block.variant--"+[option_b_val,option_a_val].join('--').toLowerCase().replace(/\s+/g,'-');
						if ( $(selector).length > 0 ) {
							window.console.log(option_a_val,option_b_val)
						}
					})
				}
			})
		})
	})



	$variant_selected.html(selected_option)
	return false;
})

window.$ = $




